/*
 * @Date: 2024-09-03 10:35:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-09-05 09:31:01
 * @FilePath: \zyt-mobile-frontend\src\router\routerRules.js
 */
const rules = {
  // 业务用户类型
  // 0：企业
  business: "0",

  // 1：普通个人
  personal: "", // 未使用

  // 2：集体个人
  group: "zlb_community",

  // 3：放映员个人
  screen: "quxian_fygs_fyy",

  // 4.超级用户
  super: "admin",
}

export default rules
