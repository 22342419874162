import Vue from 'vue';

Vue.directive('isDev', {
  inserted(el) {
    // 检查当前环境是否为开发环境
    if (process.env.NODE_ENV !== 'development') {
      // 如果不是开发环境，则移除元素
      el.parentNode && el.parentNode.removeChild(el);
    } else {
      // 在开发环境下，设置提示和样式
      el.title = "这是开发调试组件"; // 添加鼠标悬停提示
      el.style.border = "1px dashed black"; // 设置边框样式
      el.style.padding = "5px"; // 可以适当添加内边距
      el.style.margin = "5px 0"; // 添加外边距以美化显示
      el.style.overflow = "scroll"; // 溢出内容
      el.style.backgroundColor = "rgba(44,62,80,0.9)"; // 设置背景色
      el.style.color = "white"; // 设置字体颜色
      el.style.fontSize = "11px"; // 设置字体大小
    }
  }
});
