import axios from "axios"
import store from "../store"
import router from "../router"
import qs from "qs"
import { Toast } from "vant"
// 创建axios实例
const service = axios.create({
  // baseURL:'/api',
  baseURL: process.env.VUE_APP_URL, // api的base_url
  timeout: 10000, // 请求超时时间
  headers: {
    "Content-Type": "application/json",
  },
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    config.url = config.apiUrl
    Toast.loading({
      message: "正在加载",
      duration: 0,
      forbidClick: true,
    })
    if (config.method === "get") {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2,不转换会显示为arr[]=1&arr[]=2
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {
          arrayFormat: "repeat",
        })
      }
    }
    if ("GET" == config.method.toUpperCase()) {
      config.params = config.data
    }
    if (store.state.token) {
      config.headers["X-Access-Token"] = store.state.token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  (error) => {
    console.log(error) // for debug
    Promise.reject(error)
  },
)

service.interceptors.response.use(
  (response) => {
    Toast.clear()
    // 如果返回的状态码为200，说明接口请求成功
    if (response.status === 200) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response.data)
    }
  },
  (error) => {
    Toast.clear()
    if (error.response) {
      let data = error.response.data
      const token = store.state.token
      console.log("------异常响应------", token)
      switch (error.response.status) {
        case 403:
          Toast.fail("拒绝访问!")
          break
        case 500:
          console.log("------error.response------", error.response)
          Toast.fail("服务端异常!")
          break
        case 404:
          Toast.fail("很抱歉，资源未找到!")
          break
        case 504:
          Toast("网络超时！")
          break
        case 401:
          Toast.fail("请重新进入应用！")
          router.replace("/login")
          break
        default:
          Toast(data.message)
          break
      }
    }
    return Promise.reject(error)
  },
)

function switchUrl(url) {
  var str = url.split(".")[3]
  var reg = new RegExp("0", "g")
  var newUrl = str.replace(reg, "/")
  return newUrl
}
export default service
