<!--
 * @Date: 2023-11-20 16:58:16
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-08-16 16:27:04
 * @FilePath: \zyt-mobile-frontend\src\App.vue
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import store from './store'
import {Dialog, Toast} from 'vant'
import { api } from '@/api/index'

export default {
  data() {
    return {}
  },
  watch: {
    $route(to, from) {
      ZWJSBridge.onReady(() => {
        console.log('标题修改')
        ZWJSBridge.setTitle({
          title: to.name ? to.name : '浙影通',
        })
        //页面埋点
        api
          .addPageView({
            dataSource: 1,
            requestSource: 1,
          })
          .then((res) => {
            sensors_sw.quick('autoTrackSinglePage')
          })
      })
    },
  },
  mounted() {
    console.log('入口')
    // ZWJSBridge.getLocation()
    //   .then((result) => {
    //     console.log('result', result)
    //     this.$store.commit('changeUserAddress', [result.city, result.region])
    //     this.$store.commit('changeUserAddressLocation', result)
    //     // Toast("获取定位成功");
    //     // this.location = result;
    //     // resolve(result);
    //   })
    //   .catch((error) => {
    //     console.log('error', error)
    //     // reject(error);
    //   })

  },
  methods: {},
}
</script>
<style lang="less">
#app {
  font-family: Roboto, 'PingFang SC';
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: #f6f7f9;
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
</style>
