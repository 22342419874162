/*
 * @Date: 2023-10-11 17:16:58
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-08-15 17:23:34
 * @FilePath: \zyt-mobile-frontend\src\utils\vant.js
 */
//vant组件全局注册
import Vue from "vue"
import {
  Form,
  Field,
  Button,
  Search,
  Tab,
  Tabs,
  List,
  Cascader,
  Popup,
  Toast,
  Icon,
  DropdownMenu,
  DropdownItem,
  Rate,
  Cell,
  CellGroup,
  Image as VanImage,
  Lazyload,
  Calendar,
  Picker,
  PullRefresh,
  Uploader,
  Row,
  Checkbox,
  DatetimePicker,
  Dialog,
  Empty,
  Skeleton,
  NavBar,
  Tag,
  Slider,
  Grid,
  GridItem,
  NoticeBar,
    CountDown,
    Sidebar,
    SidebarItem,
    Col
} from "vant"

Vue.use(Form)
  .use(Field)
  .use(Button)
  .use(Search)
  .use(Tab)
  .use(Tabs)
  .use(List)
  .use(Cascader)
  .use(Popup)
  .use(Toast)
  .use(Icon)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Rate)
  .use(Cell)
  .use(CellGroup)
  .use(VanImage)
  .use(Lazyload)
  .use(Calendar)
  .use(Picker)
  .use(PullRefresh)
  .use(NavBar)
  .use(Uploader)
  .use(Row)
  .use(Checkbox)
  .use(DatetimePicker)
  .use(Dialog)
  .use(Empty)
  .use(Skeleton)
  .use(Tag)
  .use(Slider)
  .use(Grid)
  .use(GridItem)
  .use(NoticeBar)
  .use(CountDown)
  .use(Sidebar)
  .use(SidebarItem)
  .use(Col)
