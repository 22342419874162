/*
 * @Date: 2023-11-27 10:41:00
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-11-15 00:30:32
 * @FilePath: \zyt-mobile-frontend\src\api\zjApi.js
 */
// TODO 发布前要放开
import service from '@/utils/request'

export default {
  /**
   *
   *
   * */
  getPs(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/Integrate",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * @description: 获取影院竞对票价分析列表
   * @param {*} params UserCode=1&ourCinemaId=33010201&datetype=before7days
   * @return {*}
   */
  getAnalysisPrice(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/AnalysisPrice",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * @description: 获取影院竞对排片分析列表
   * @param {*} params UserCode=1&ourCinemaId=33010201&datetype=before7days
   * @return {*}
   */
  getAnalysisPriceInfo(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/AnalysisPriceInfo",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //   上座率分析
  // 1.获取影院和竞对影院上座率列表
  // /Api/AnalysisSeatRate?UserCode=1&ourCinemaId=33010201
  // 2.获取影院与竞对影院上座率详情
  // /Api/AnalysisSeatRateInfo?UserCode=1&ourCinemaId=33010201&competitorCinemaId=33017941

  /**
   * @description: 获取影院竞对上座率分析列表
   * @param {*} params UserCode=1&ourCinemaId=33010201
   * @return {*}
   */
  getAnalysisSeatRate(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/AnalysisSeatRate",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * @description: 获取影院竞对上座率详情
   * @param {*} params UserCode=1&ourCinemaId=33010201&competitorCinemaId=33017941
   * @return {*}
   */
  getAnalysisSeatRateInfo(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/AnalysisSeatRateInfo",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  //   排⽚场次分析
  // 1.获取影院和竞对影院排⽚场次列表
  // /Api/AnalysisSession?UserCode=1&ourCinemaId=33010201&datetype=before7days
  // 2.获取影院和竞对影院排⽚场次详情
  // /Api/AnalysisSessionInfo?UserCode=1&ourCinemaId=33010201&competitorCinemaId=33017941&date=2023-11-28
  /**
   * @description: 获取影院竞对排片场次分析列表
   * @param {*} params UserCode=1&ourCinemaId=33010201&datetype=before7days
   * @return {*}
   */
  getAnalysisSession(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/AnalysisSession",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * @description: 获取影院竞对排片场次详情
   * @param {*} params UserCode=1&ourCinemaId=33010201&competitorCinemaId=33017941&date=2023-11-28
   * @return {*}
   */
  getAnalysisSessionInfo(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/AnalysisSessionInfo",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //   排⽚效益分析
  // 1. 获取影院和竞对影院排⽚场次列表
  // /Api/AnalysisBenefit?UserCode=1&ourCinemaId=33010201&datetype=before7days
  // 2.获取影院和竞对影院排⽚效益详情
  // /Api/AnalysisBenefitInfo?UserCode=1&ourCinemaId=33010201&competitorCinemaId=33017941&datetype=before7days
  /**
   * @description: 排⽚效益分析- 获取影院和竞对影院排⽚场次列表
   * @param {*} params UserCode=1&ourCinemaId=33010201&datetype=before7days
   * @return {*}
   */
  getAnalysisBenefit(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/AnalysisBenefit",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * @description: 排⽚效益分析- 获取影院和竞对影院排⽚效益详情
   * @param {*} params UserCode=1&ourCinemaId=33010201&competitorCinemaId=33017941&datetype=before7days
   * @return {*}
   */
  getAnalysisBenefitInfo(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/AnalysisBenefitInfo",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // 获取影院竞对影院列表
  // 1. 获取影院竞对影院列表，示例
  // /Api/CompetitorList?UserCode=1&ourCinemaId=33010201
  /**
   * @description: 获取影院竞对影院列表
   * @param {*} params UserCode=1&ourCinemaId=33010201
   * @return {*}
   */
  getCompetitorList(params) {
    return new Promise((resolve, reject) => {
      service({
        apiUrl: "/zj/Api/CompetitorList",
        url: "mgop.zhijiang.zytv3.api0Integrate",
        method: "get",
        data: params,
      })
        .then((res) => {
          resolve(res)
          console.log(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}
