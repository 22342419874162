import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    // 存储token
    token: "",
    //  浙里办用户类型 0:公务员 1：个人 2：法人
    zlbUserType: 0,
    // 业务用户类型  0：企业 1：普通个人 2：集体个人 3：放映员个人
    userType: 0,
    //个人用户名称
    userName: "",
    //浙里办用户id
    zlbUserId: "",
    //浙里办法人用户信息
    userInfo: {},
    // 用户当前定位位置
    userAddress: [],
    // 用户当前定位位置
    userAddressLocation: {},
    // 用户放映点
    userPoint: null,
    // 放映员放映点
    projectorPoint: null,
    // 适老化
    isOld: false,
    selectedCinema: null, // 存储选中的影院信息
  },
  getters: {},
  mutations: {
    changeToken(state, token) {
      if (token) {
        state.token = token
      }
    },
    changeIsOld(state, isOld) {
      state.isOld = isOld
    },
    changeUserName(state, name) {
      if (name) {
        state.userName = name
      }
    },
    changeZlbUserId(state, uid) {
      if (uid) {
        state.zlbUserId = uid
      }
    },
    changeUserType(state, type) {
      if (type) {
        state.userType = type
      }
    },
    changeZlbUserType(state, type) {
      if (type) {
        state.zlbUserType = type
      }
    },
    changeUserInfo(state, info) {
      if (info) {
        state.userInfo = info
      }
    },
    changeUserAddress(state, address) {
      if (address) {
        state.userAddress = address
      }
    },
    changeUserAddressLocation(state, location) {
      if (location) {
        state.userAddressLocation = location
      }
    },

    changeuserPoint(state, point) {
      if (point) {
        state.userPoint = point
      }
    },
    changeProjectorPoint(state, point) {
      if (point) {
        state.projectorPoint = point
      }
    },
    changeSelectedCinema(state, cinema) {
      state.selectedCinema = cinema
    },
  },
  actions: {
    changeToken(context, token) {
      context.commit("changeToken", token)
    },
    changeUserType(context, type) {
      context.commit("changeUserType", type)
    },
    changeZlbUserId(context, uid) {
      context.commit("changeZlbUserId", uid)
    },
    changeZlbUserType(context, type) {
      context.commit("changeZlbUserType", type)
    },
    changeUserInfo(context, info) {
      context.commit("changeUserInfo", info)
    },
    changeUserName(context, name) {
      context.commit("changeUserName", name)
    },
    changeUserAddress(context, address) {
      context.commit("changeUserAddress", address)
    },
    changeUserAddressLocation(context, location) {
      context.commit("changeUserAddressLocation", location)
    },
    changeuserPoint(context, point) {
      context.commit("changeuserPoint", point)
    },
    changeProjectorPoint(context, point) {
      context.commit("changeProjectorPoint", point)
    },
    changeSelectedCinema({ commit,cachedCinema }) {
        commit('changeCinema', point)
    },
  },
})

export default store
