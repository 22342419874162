/*
 * @Date: 2024-09-03 10:35:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-14 14:19:34
 * @FilePath: \zyt-mobile-frontend\src\utils\mgopReq.js
 */
import store from "../store"
import {Dialog, Toast} from "vant"
import {mgop} from "@aligov/jssdk-mgop"

// 封装请求方法
const service = (options) => {
    const header = {
        // "isTestUrl":"1", // 提交浙里办必须注释 走联调环境
        "X-Access-Token": store.state.token,
        ...(options.header || {})
    };

    return new Promise((resolve, reject) => {
        mgop({
            api: options.url, // 必须
            host: "https://mapi.zjzwfw.gov.cn/",
            dataType: "JSON",
            data: options.data,
            type: options.method || "GET",
            timeout: 10000,
            header: header,
            appKey: "utu63f96+2002427148+dmkzts", // 必须 IRS平台
            onSuccess: (response) => {
                console.log({response});
                // ZWJSBridge.alert({
                //     title:options.url,
                //     message:JSON.stringify(response.data)
                // })
                if (response.data.code === 200) {
                    resolve(response.data);
                } else {
                    handleErrorResponse(response.data);
                }
            },
            onFail: (err) => {
                if (err.errorMessage === "httpcode-404") {
                    Toast(`${options.url} 资源未找到`);
                } else {
                    Toast("请求失败，请稍后重试");
                }
                console.error(err);
                reject(err);
            },
        });
    });
};

// 处理错误响应
const handleErrorResponse = (data) => {
    if (data.message) {
        Toast(data.message);
    }
    if (data.code === 401) {
        handleUnauthorizedAccess();
    }
};

// 处理未授权访问
const handleUnauthorizedAccess = () => {
    ZWJSBridge.getUserType().then((r) => {
        const baseUrl = "https://puser.zjzwfw.gov.cn/sso/";
        const serviceCode = "BCDSGA_db0988b5e8dc97700c8205be915fe959";
        let redirectUrl;

        if (r.userType === 1) {
            if (judgeEnv()) {
                redirectUrl = `${baseUrl}alipay.do?action=ssoLogin&scope=1&servicecode=${serviceCode}`;
            } else {
                redirectUrl = `${baseUrl}mobile.do?action=oauth&scope=1&servicecode=${serviceCode}`;
            }
        } else {
            redirectUrl = "https://esso.zjzwfw.gov.cn/opensso/spsaehandler/metaAlias/sp?spappurl=https://zyt.xcb.zj.gov.cn:7000/zyt/zlb/mobile/legalPersonSingleSignOn";
        }

        window.location.replace(redirectUrl);
    });
};

// 判断是 app 还是小程序
const judgeEnv = () => {
    const sUserAgent = window.navigator.userAgent.toLowerCase();
    return sUserAgent.includes("miniprogram") && sUserAgent.includes("alipay");
};

export default service;
