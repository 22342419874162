/*
 * @Date: 2024-08-22 12:45:27
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-11-12 23:08:35
 * @FilePath: \zyt-mobile-frontend\src\router\ruralFilm.js
 */
import rules from "./routerRules"

const routers = [
    {
        path: "/ruralFilm",
        name: "公益放映",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@/views/ruralFilm/index"),
    },
    // 主题详情
    {
        path: "/ruralFilm/topic/:id",
        name: "主题详情",
        component: () => import("@/views/ruralFilm/pages/topic.vue"),
    },
    // 我要点播
    {
        path: "/onDemand",
        name: "我要点播",
        meta: {
            keepAlive: true,
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/onDemand"),
    },
    // 点播成功
    {
        path: "/onDemand/success",
        name: "点播成功",
        meta: {
            keepAlive: true,
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/onDemandSuccess"),
    },
    // 观影地图
    {
        path: "/ruralFilm/pointMap",
        name: "观影地图",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@/views/ruralFilm/pages/pointMap/index"),
    },
    // 选择放映点
    {
        path: "/ruralFilm/selectPoint",
        name: "选择放映点",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/selectPoint/index.vue"),
    },
    // 放映点查询
    {
        path: "/ruralFilm/searchPoint",
        name: "放映点查询",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/searchPoint/index"),
    },
    // 放映点详情
    {
        path: "/ruralFilm/pointDetail/:id",
        name: "放映点详情",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/pointDetail/index.vue"),
    },
    // 周边放映
    {
        path: "/ruralFilm/nearbyCinema",
        name: "周边放映",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/nearbyCinema/index"),
    },
    // 周边放映影片详情
    {
        path: "/ruralFilm/nearbyCinema/filmDetail",
        name: "周边放映影片详情",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/filmAroundDetail/index.vue"),
    },
    // 影片详情
    {
        path: "/ruralFilm/filmDetail/:id",
        name: "影片详情",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/filmDetail/index.vue"),
    },
    // 提交点播
    {
        path: "/submitOnDemand",
        name: "提交点播",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/submitOnDemand"),
    },
    // 个人-点播拼团
    {
        path: "/joinGroup",
        name: "点播拼团",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/joinGroup/index.vue"),
    },
    // 放映公告
    {
        path: "/sessionPlan",
        name: "放映公告",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/FilmBillboard/index"),
    },
    // 放映场次评价
    {
        path: "/sessionEvaluate",
        name: "放映场次评价",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/sessionEvaluate/index"),
    },
    // 评价提交
    {
        path: "/evaluateSubmit",
        name: "评价提交",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/evaluateSubmit/index.vue"),
    },
    // 集体-点播
    {
        path: "/ruralFilm/groupOnDemand",
        name: "集体点播",
        meta: {
            rules: [rules.group, rules.super],
        },
        component: () => import("@views/ruralFilm/pages/groupOnDemand/index.vue"),
    },
    // 集体-点播记录
    {
        path: "/ruralFilm/groupOnDemandRecord",
        name: "集体点播记录",
        meta: {
            rules: [rules.group, rules.super],
        },
        component: () => import("@/views/me/playRecord/index"),
    },
    // 集体-放映报告列表
    {
        path: "/group/representApproval",
        name: "放映报告审核",
        meta: {
            rules: [rules.group, rules.super],
        },
        component: () => import("@views/me/representApproval/index.vue"),
    },
    // 集体-放映报告详情
    {
        path: "/group/representApproval/detail/:id",
        name: "放映报告审核",
        meta: {
            rules: [rules.group, rules.super],
        },
    component: () => import("@views/me/representApproval/detail.vue"),
    },
    // 放映员风采
    {
        path: "/projectionists",
        name: "放映员风采",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/projectionists"),
    },
    // 我的-放映员-放映计划
    {
        path: "/screenPlans",
        name: "放映计划",
        meta: {
            rules: [rules.screen, rules.super],
        },
        component: () => import("@/views/me/screensPlan/index"),
    },
    // 我的-放映员-放映计划-确认页
    {
        path: "/screenPlans/confirm",
        name: "放映计划-确认页",
        meta: {
            rules: [rules.screen, rules.super],
        },
        component: () => import("@/views/me/screenPlanConfirm/index"),
    },
    // 我的-放映员-放映打卡
    {
        path: "/screenRecord",
        name: "放映打卡",
        meta: {
            rules: [rules.screen, rules.super],
        },
        component: () => import("@/views/me/screenRecord/index"),
    },
    // 放映报告列表
    {
        path: "/screenReports",
        name: "放映报告列表",
        meta: {
            rules: [rules.screen, rules.super],
            // keepAlive: true,
        },
        component: () => import("@/views/me/screenReports/index"),
    },
    // 我的-放映员-评价二维码
    {
        path: "/qrEvaluate",
        name: "评价二维码",
        meta: {
            rules: [rules.screen, rules.super],
        },
        component: () => import("@/views/me/evaluateQr/index"),
    },
    // 我的-放映员-点播
    {
        path: "/screenDemand",
        name: "点播",
        meta: {
            rules: [rules.screen, rules.super],
        },
        component: () => import("@/views/me/screenDemand/index"),
    },
    // 我的-放映员-放映计划-点播-点播详情
    {
        path: "/screenDemand/edit",
        name: "点播详情-编辑",
        meta: {
            rules: [rules.screen, rules.super],
        },
        component: () => import("@/views/me/screenDemand/modules/edit"),
    },
    // 我的-放映员-放映计划-点播-点播详情
    {
        path: "/screenDemandDetail",
        name: "点播详情-编辑",
        meta: {
            rules: [rules.screen, rules.super],
        },
        component: () => import("@/views/me/screenDemand/modules/detail"),
    },
    // 我的-放映员-放映报告-详情
    {
        path: "/screenReportDetail/:id",
        name: "放映报告详情",
        meta: {
            rules: [rules.screen, rules.super],
        },
        component: () => import("@/views/me/screenReports/modules/detail"),
    },
    //新闻详情
    {
        path: "/newsDetail",
        name: "新闻详情",
        meta: {
            rules: [
                rules.business,
                rules.personal,
                rules.group,
                rules.screen,
                rules.super,
            ],
        },
        component: () => import("@views/ruralFilm/pages/newsDetail"),
    },
]
export default routers
